<template>
  <div>
    <Back title="重置密码" />
    <div :class="$style.content">
      <img :class="$style.logo" src="@/assets/img/logo.png" />
      <div :class="$style.filedWrap">
        <p :class="$style.label">手机号</p>
        <van-field
          :class="$style.field"
          v-model="mobile"
          type="tel"
          maxlength="11"
          placeholder="请输入手机号"
        />
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">验证码</p>
        <div :class="$style.codeWrap">
          <van-field
            :class="[$style.field, $style.code]"
            v-model="code"
            placeholder="请输入验证码"
          >
          </van-field>
          <Code :mobile="mobile" type="2" />
        </div>
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">设置密码</p>
        <van-field
          :class="$style.field"
          v-model="pwd"
          type="password"
          placeholder="请输入6-20位登录密码"
        />
      </div>
      <div :class="$style.filedWrap">
        <p :class="$style.label">确认密码</p>
        <van-field
          :class="$style.field"
          v-model="confirmPwd"
          type="password"
          placeholder="请再次输入6-20位登录密码"
        />
      </div>
      <van-button
        :class="$style.btn"
        round
        block
        @click="handleUpdatePwd"
        :color="canSubmit ? '#212121' : '#707070'"
      >
        确认</van-button
      >
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import Code from "@/components/Code";
import { forgetPwd } from "@/api/";
import { md5 } from "@/utils/util";
export default {
  components: {
    Back,
    Code,
  },
  computed: {
    canSubmit() {
      return this.mobile && this.code && this.pwd && this.confirmPwd;
    },
  },
  data() {
    return {
      mobile: "",
      code: "",
      pwd: "",
      confirmPwd: "",
    };
  },
  methods: {
    handleUpdatePwd() {
      if (!this.canSubmit) return;
      const data = {
        mobile: this.mobile,
        sms_code: this.code,
        password: md5(this.pwd),
        password_confirmation: md5(this.confirmPwd),
      };
      forgetPwd(data).then(() => {
        this.$toast.success("密码重置成功！");
        // this.$router.replace("/login");
      });
    },
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 0 28px;
  .logo {
    width: 173px;
    margin: 34px auto;
    display: block;
  }
  .filedWrap {
    margin-bottom: 20px;
    .label {
      line-height: 20px;
      margin-bottom: 7px;
      padding-left: 16px;
    }
    .codeWrap {
      display: flex;
    }
    .field {
      height: 44px;
      line-height: 44px;
      background: #212121;
      border-radius: 27px;
      align-items: center;
      color: #fff;
      input::-webkit-input-placeholder {
        color: #666666;
      }
      &.code {
        width: 178px;
        margin-right: 7px;
        &::after {
          display: none;
        }
      }
      :global {
        .van-field__control {
          color: #fff;
        }
      }
    }
  }
  .btn {
    margin-top: 35px;
  }
}
</style>
